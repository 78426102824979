:root {
  --color-principal: #35573d;
  --color-principal-claro: #7eb98c;
  --color-principal-super-claro: #effef2;
  --color-secundario: #d7ab02;
  --color-fondo: #f5f5f5;
  --color-texto: #333333;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
   font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f9fa;
}

/*----------------------- scroll -----------------------------*/

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 16px;
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 16px;
  transition: all 300ms ease-in;

}

::-webkit-scrollbar-thumb:hover {
  transition: all 300ms ease-in;
  background: #949393;
}

/*----------------------- alerts -----------------------------*/

.Content_Swall {
  margin-top: 20px;
  border: solid 2px rgb(106, 250, 137) !important;
  border-radius: 1rem;
  width: 500px !important;
  height: 4rem;
  display: flex !important;
  justify-content: space-evenly !important;
  background: white;
  align-items: center;
  box-shadow: none !important;
  z-index: 999 !important;
}

.Content_Swall_Info {
  margin-top: 20px;
  border: solid 2px rgb(106, 228, 250) !important;
  border-radius: 1rem;
  width: 500px !important;
  height: 4rem;
  display: flex !important;
  justify-content: space-evenly !important;
  background: white;
  align-items: center;
  box-shadow: none !important;
  z-index: 999 !important;
}

.Content_Swall_error {
  margin-top: 20px;
  border: solid 2px rgb(254, 54, 54) !important;
  border-radius: 1rem;
  width: 650px !important;
  height: 4rem;
  display: flex !important;
  justify-content: space-evenly !important;
  background: white;
  align-items: center;
  box-shadow: none !important;
  z-index: 999 !important;
}